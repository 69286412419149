@import url('https://fonts.googleapis.com/css2?family=Nanum+Pen+Script&display=swap')
@import url('https://fonts.googleapis.com/css2?family=Assistant:wght@200..800&display=swap')

$material-base: #d9d9d9
$material-inset: #cfcece

$text-base: #171717
$text-hover: #2c2c2c
$text-down: #3d3d3d
$font-hand: 'Nanum Pen Script', Arial, Helvetica, sans-serif
$font-sans: 'Assistant', Arial, Helvetica, sans-serif

html
    background-color: $material-base

#root
    display: flex
    height: 100vh

.center-wrapper
    display: grid
    color: $text-base
    align-content: center
    justify-content: center
    justify-items: center
    text-align: center
    margin: auto
    padding: 8px
    max-width: 640px
    font-family: $font-hand
    gap: 4px

.name
    font-size: 40px

.face-logo
    width: 240px
    margin-bottom: 16px

.main-button
    background-color:$text-base
    border-radius: 24px
    border: none
    font-family: $font-sans
    font-weight: 600
    letter-spacing: 0.25px
    font-size: 16px
    padding: 8px 16px
    color: $material-base
    cursor: pointer
    transition: all 100ms ease
    position: relative
    &:hover
        background-color: $text-hover
    &:active
        background-color: $text-down
    &:focus-visible
        outline: 2px solid #0000ff
    .pulse
        position: absolute
        top: 0
        left: 0
        z-index: -99
        border-radius: 24px
        background-color: #00000050
        animation: pulse 4s infinite
        width: 100%
        height: 100%

.subheader
    line-height: 100%
    font-size: 24px
    min-height: 52px

.generated-text
    line-height: 120%
    text-align: left
    font-size: 24px
    font-family: $font-hand

@keyframes pulse 
  0% 
    transform: scaleX(1) scaleY(1)
  25% 
    transform: scaleX(1.1) scaleY(1.3)
  100%
    transform: scaleX(1.1) scaleY(1.3)
    opacity: 0%
  
.content-block
    display: flex
    flex-direction: column
    margin-top: 8px
    align-items: flex-start
    font-family: $font-sans
    font-weight: 500
    gap: 8px
    padding: 16px
    background-color: $material-inset
    border-radius: 16px

.input-group
    display: flex
    width: 100%
    gap: 8px

.color-input
    font-family: $font-sans
    flex-grow: 1
    font-size: 18px
    padding: 8px
    border: none
    border-radius: 8px

.color-results
    display: flex
    align-self: stretch
    font-size: 14px
    flex-direction: column
    font-family: $font-sans
    border-radius: 16px

.color-cost
    font-size: 20px
    margin-top: 12px
    margin-bottom: 8px
    font-family: $font-hand

.link
    font-size: 16px
    font-weight: 500
    margin: 16px
    font-family: $font-sans
    a
        padding: 2px 4px
        border-radius: 4px

.avatar-container
    display: flex
    flex-direction: row
    align-items: center
    gap: 8px
    margin-top: 24px
    .avatar
        width: 24px
        border-radius: 6px
    .avatar-label
        font-family: $font-sans
        font-weight: 600
        line-height: 16px
        font-size: 16px